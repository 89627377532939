<template>
    <div>
      <form v-on:submit.prevent="saveOffer" autocomplete="off" data-vv-scope="add-offer">
        <div class="col-xs-4 col-sm-4">
            <input
                v-validate="'required'"
                type="text"
                placeholder="Offer Name"
                name="name"
                v-model="offer.name"
                maxlength="23"
                class="input"
                aria-required="true"
                aria-invalid="false"
                :class="{'input': true, 'is-danger': errors.has('add-offer.name') }"
            />
        </div>
        <div class="col-xs-4 col-sm-4">
            <input
                v-validate="'required'"
                type="number"
                placeholder="Price"
                name="price"
                v-model="offer.price"
                class="input"
                aria-required="true"
                aria-invalid="false"
                :class="{'input': true, 'is-danger': errors.has('add-offer.price') }"
            />
        </div>
        <div class="col-xs-4 col-sm-4">
            <input
                v-validate="'required'"
                type="number"
                placeholder="Credits"
                name="credits"
                v-model="offer.credits"
                class="input"
                aria-required="true"
                aria-invalid="false"
                :class="{'input': true, 'is-danger': errors.has('add-offer.credits') }"
            />
        </div>
        <div class="col-xs-12 col-sm-12">
          <textarea
              name="description"
              v-validate="'required'"
              maxlength="60"
              placeholder="Description - 60 Characters Max"
              rows="2"
              class="input textarea"
              v-model="offer.description"
              :class="{'input': true, 'is-danger': errors.has('add-offer.description') }"
          ></textarea>
        </div>
        <div class="green-border-box mb10 checklist" v-if="!editOfferFlag">
          <div class="col-sm-6">
            <div class="checklist-head">
              <input type="checkbox" id="all-stores" v-model="selectAll" @change="onSelectAll">
              <label for="all-stores">Select Brands</label>
              <div class="filter-control">
                <!-- <input type="text" class="btn-select font-14px" v-model="brandName" placeholder="Brand Name"> -->
              </div>
            </div>
            <VuePerfectScrollbar class="checklist-contain" >
              <ul class="checklist-body" >
                <li
                  v-for="brand in brands"
                  :key="brand.id"
                  :class="{ selected: selectAll || brand.selected }"
                  @click="setSelected(brand , !brand.selected)"
                >
                  <span class="checklist-item">
                    <span :title="brand.name">{{brand.name}}</span>
                    <!-- <span :title="brand.tagline" class="address">{{brand.tagline}}</span> -->
                      <img
                        v-if="inSelectedBrandsList(brand)"
                        src="/img/Create Pickl/storenameSelect_icon.png"
                      >
                  </span>
                </li>
              </ul>
            </VuePerfectScrollbar>
          </div>
          <div class="col-sm-6">
            <div class="checklist-head">
              <label for="all-stores">Selected Brands</label>
            </div>
            <VuePerfectScrollbar class="checklist-contain" >
              <ul class="checklist-body" v-if="selectedBrands.length > 0 ">
                <li
                  v-for="(sb, index) in selectedBrands"
                  :key="sb.id"
                >
                  <span class="checklist-item">
                    <span :title="sb.name">{{sb.name}}</span>
                    <!-- <span :title="sb.tagline" class="address">{{sb.tagline}}</span> -->
                    <img
                      src="/img/Create Pickl/storecancel_icon.png"
                      class="remove-icon"
                      @click="removeBrand(index)"
                      style="border:solid 1px gray; border-radius: 50px;"
                    >
                  </span>
                </li>
              </ul>
              <h3 v-else style="text-align:center;">No Brand Selected</h3>
            </VuePerfectScrollbar>
          </div>
        </div>
        <button
                type="submit"
                class="btn-lg-green"
                :disabled="submittingForm"
            >{{editOfferFlag?'Update':'Add'}} Offer</button>
    </form>
    </div>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import DatePicker from 'vue2-datepicker'
let date = new Date()

export default {
  components: {
    VuePerfectScrollbar
  },
  props: {
    offer: Object,
    editOfferFlag: Boolean
  },
  data () {
    return {
      submittingForm: false,
      brandLimitPerPage: 1000,
      brandOffset: 0,
      brands: [],
      selectedBrands: [],
      selectAll: false,
      starteDate: new Date(),
      expireDate: new Date(date.setMonth(date.getMonth() + 1))

    }
  },
  mounted () {
    if (!this.editOfferFlag) {
      this.getBrands()
    }
  },
  methods: {
    onSelectAll () {
      if (this.selectAll) {
        this.selectedBrands = []
        this.brands.forEach((x) => {
          if (this.selectedBrands.indexOf(x) === -1) {
            this.selectedBrands.push({ id: x.id, name: x.name, tagline: x.taglne })
          }
        })
      } else {
        this.selectedBrands = []
      }
    },

    removeBrand (index) {
      this.selectedBrands.splice(index, 1)
    },
    setSelected (brand, selected) {
      let isObjectPresent = this.selectedBrands.find((o) => o.id === brand.id)
      if (typeof isObjectPresent !== 'undefined') {
        this.$notify({ type: 'error', text: 'Brand Already selected' })
        return false
      }
      this.selectedBrands.push({ id: brand.id, name: brand.name, tagline: brand.taglne })
    },

    inSelectedBrandsList (brand) {
      let isObjectPresent = this.selectedBrands.find((o) => o.id === brand.id)
      if (typeof isObjectPresent !== 'undefined') {
        return true
      } else {
        return false
      }
    },
    getBrands (clear = true) {
      this.$store.dispatch('getBrands', { limit: this.brandLimitPerPage, offset: this.brandOffset }).then((response) => {
        this.brands.push(...response.response.body.data)
        if (this.brands.length < response.response.body.count) {
          this.brandOffset += this.brandLimitPerPage
          ßthis.getBrands()
        }
      })
    },

    saveOffer () {
      this.$validator.validateAll('add-offer').then((result) => {
        if (result) {
          let action = 'save'
          if (this.editOfferFlag) {
            action = 'update'
          } else {
            this.offer.type = 'USER_DEFINED'
          }
          this.$store.dispatch(action + 'Offer', this.offer).then((response) => {
            if (action === 'save' && this.selectedBrands.length > 0) {
              if (this.selectedBrands.length > 0) {
                this.assignOfferBrands(response.body.id)
              }
            }
            this.$router.push('/sadmin/offers')
            this.$notify({ type: 'success', text: 'Offer has been ' + action + 'd successfully!' })
          }, (response) => {
            this.busy = false
          })
        }
      })
    },

    assignOfferBrands (offerId) {
      let brandIds = []
      this.selectedBrands.forEach(brand => {
        brandIds.push({ id: brand.id })
      })
      let payload = {
        offerId: offerId,
        brands: brandIds,
        startDate: new Date(),
        expireDate: this.expireDate,
        transactionId: 0
      }
      this.$store.dispatch('bulkAssignOfferBrands', payload).then((response) => {
        return true
      }, (response) => {
        this.busy = false
      })
    }
  }
}
</script>

<style scoped>
.modal-dialog {
  /* max-width: 590px; */
  /* max-width: 690px; */
}
.modal-dialog.modal-lg {
  max-width: 100%;
}

p {
  color: #232323;
  font-size: 24px;
  text-align: center;
}

.attr-imgs {
  padding: 20px 10px 0;
  max-height: 650px;
  overflow: auto;
}

.photo {
  padding: 0 10px;
}

.img-contain {
  position: relative;
  margin-bottom: 20px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.img-contain:before {
  padding-top: 150%;
  display: block;
  content: " ";
}

/* .img-contain img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
} */

.img-contain .img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  font-weight: 500;
  font-size: 17px;
  text-transform: capitalize;
}

.img-contain .img-overlay span {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
}

.img-contain .img-overlay .img-tick {
  border-radius: 50%;
  background-color: #782b55;
  font-size: 17px;
  width: 26px;
  height: 26px;
  display: none;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
}

.vertical-center-transform {
  width: 100%;
  text-align: center;
}

.attr-imgs input[type="checkbox"] {
  display: none;
}

.attr-imgs
  input[type="checkbox"]:checked
  + .img-contain
  .img-overlay
  .img-tick {
  display: block;
}

select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}

option {
  color: black;
  background-color: white;
}

.create-progress {
  position: relative;
  text-align: center;
  height: 65px;
}

hr {
  border-width: 2px;
  border-color: #dedede;
  margin: 15px 0 0;
  display: inline-block;
  transition: all 0.2s ease-out;
}

hr.done {
  border-color: #6f3053;
}

.progress-line {
  width: calc(100% - 58px);
  margin: 0 auto;
}

.step-part {
  text-align: center;
  position: absolute;
  top: 0;
}

.step-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #dedede;
  color: #646464;
  display: inline-block;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  padding: 6px;
  transition: all 0.2s ease-out;
  background-image: none;
}

.step-circle .step-label {
  color: black;
  position: absolute;
  bottom: -30px;
  left: 0;
  font-size: 17px;
  transition: all 0.2s ease-out;
}

.step-part:nth-child(1) {
  width: 64px;
  left: 0;
}

.step-part:nth-child(2) {
  width: 44px;
  left: calc(50% - 22px);
}

.step-part:nth-child(3) {
  width: 51px;
  right: 0;
}

.step-circle.active {
  background-color: #00e05e;
  color: white;
}

.step-circle.active .step-label {
  color: #00e05e;
}

.step-circle.done {
  background-color: #6f3053;
  background-image: url("/img/Create Pickl/activestep_icon.png");
  background-size: 32px 32px;
  background-position: top left;
  font-size: 0;
}

.step-circle.done .step-label {
  color: #6f3053;
}

.filter-row {
  margin: 0 -5px;
}

.filter-control {
  width: 20%;
  padding: 0 5px;
  display: inline-block;
  margin-right: 0;
}

.filter-control .btn-select {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
}

h5.sub-heading {
  font-size: 16px;
  margin-top: 0;
}

.subtitle {
  font-size: 18px;
  text-align: center;
  color: black;
  margin-bottom: 10px;
}

.btn-positive {
  font-weight: 500;
  padding: 5px 15px;
  line-height: 18px;
  vertical-align: bottom;
}

.btn-positive img {
  vertical-align: text-top;
}

.product-btn {
  padding: 7px 15px;
}

.product-buttons {
  line-height: 54px;
}

.error-msg {
  margin-bottom: 10px;
  text-align: left;
}

.checklist .checklist-contain {
  max-height: 286px;
}

.checklist .checklist-contain .checklist-item span {
  width: 47%;
  display: inline-block;
}

.checklist .checklist-contain .checklist-item span.address {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.checklist .checklist-contain li.selected .checklist-item span {
  width: calc(50% + 10px);
}

.checklist .checklist-contain li.selected .checklist-item span.address {
  width: calc(50% - 10px);
}

.pill-purple {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timepicker .mx-calendar-header {
  display: none;
}

.btn-select-box {
  border: 1px solid #12db6d;
  border-radius: 360px;
  background-color: #fff;
  padding: 3px 30px 3px 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  appearance: none;
  transition: 0.1s linear all;
  min-height: 32px;
  height: auto;
  max-width: 100%;
  font-size:14px;
  background-image: url(/img/Dashboard/drop_icon.png);
  background-size: 10px 8px;
  background-position: right 11px center;
  background-repeat: no-repeat;
}

.btn-select-box:hover {
  border: 1px solid #12db6d;
  border-radius: 360px;
  background-color: #fff;
  padding: 3px 30px 3px 12px;
  margin-right: 10px;
  appearance: none;
  transition: 0.1s linear all;
  min-height: 32px;
  height: auto;
  max-width: 100%;
  font-size:14px;
  background: #782b54;
  color: white;
  background-image: url(/img/Picklr/dropActive_icon.png);
  background-size: 10px 8px;
  background-position: right 11px center;
  background-repeat: no-repeat;
}

.purple {
  color: #782b55
}

.font-14px {
  font-size:14px;
}

.font-14px::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size:14px;
}

@media only screen and (max-width: 767px) {
  .filter-control {
    width: 100%;
  }

  .checklist .checklist-contain .checklist-item span,
  .checklist .checklist-contain li.selected .checklist-item span,
  .checklist .checklist-contain li.selected .checklist-item span.address {
    width: 100%;
  }

  .pill-purple {
    white-space: normal;
  }
}
</style>
